
/* Extend Array prototype to include filterInPlace function
         in-place alternative to Array.prototype.filter() */
interface Array<T> {
  filterInPlace(predicate: (v: T) => boolean): void;
}

// See https://stackoverflow.com/a/57685728/4449426
Array.prototype.filterInPlace = function<T>(predicate: (v: T) => boolean) {
  let nextPlace = 0;
  for (let value of this) {
    if (predicate(value)) {
      this[nextPlace++] = value;
    }
  }

  this.splice(nextPlace);
}